import { AppStep } from '@/constants'
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Designs',
    component: () => import(/* webpackChunkName: "designs" */ '../views/DesignsStep.vue')
  },
  {
    path: '/style',
    name: 'Style',
    component: () => import(/* webpackChunkName: "style" */ '../views/StyleStep.vue'),
    beforeEnter (to, from, next) {
      if (!store.getters.hasVisitedStep(AppStep.Designs)) {
        next({ name: 'Designs' })
      } else {
        next()
      }
    }
  },
  {
    path: '/colors',
    name: 'Colors',
    component: () => import(/* webpackChunkName: "colors" */ '../views/ColorsStep.vue'),
    beforeEnter (to, from, next) {
      if (!store.getters.hasVisitedStep(AppStep.Designs)) {
        next({ name: 'Designs' })
      } else if (!store.getters.hasVisitedStep(AppStep.Style)) {
        next({ name: 'Style' })
      } else {
        next()
      }
    }
  },
  {
    path: '/brief',
    name: 'Brief',
    component: () => import(/* webpackChunkName: "brief" */ '../views/BriefStep.vue'),
    beforeEnter (to, from, next) {
      if (!store.getters.hasVisitedStep(AppStep.Designs)) {
        next({ name: 'Designs' })
      } else if (!store.getters.hasVisitedStep(AppStep.Style)) {
        next({ name: 'Style' })
      } else if (!store.getters.hasVisitedStep(AppStep.Colors)) {
        next({ name: 'Colors' })
      } else {
        next()
      }
    }
  },
  {
    path: '/thanks',
    name: 'Thanks',
    component: () => import(/* webpackChunkName: "thanks" */ '../views/ThanksStep.vue'),
    beforeEnter (to, from, next) {
      if (!store.getters.hasVisitedStep(AppStep.Designs)) {
        next({ name: 'Designs' })
      } else if (!store.getters.hasVisitedStep(AppStep.Style)) {
        next({ name: 'Style' })
      } else if (!store.getters.hasVisitedStep(AppStep.Colors)) {
        next({ name: 'Colors' })
      } else if (!store.getters.hasVisitedStep(AppStep.Brief)) {
        next({ name: 'Brief' })
      } else {
        next()
      }
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
