import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const initialState = {
  app: {
    designs: [],
    styles: {
      classicModern: 4,
      matureYouthful: 4,
      feminineMasculine: 4,
      playfulSophisticated: 4,
      economicalLuxurious: 4,
      geometricOrganic: 4,
      abstractLiteral: 4
    },
    colors: [],
    specificColors: '',
    brief: {
      name: '',
      email: '',
      nameInLogo: '',
      slogan: '',
      description: '',
      industry: '',
      other: '',
      files: []
    }
  },
  visitedSteps: []
}

export default new Vuex.Store({
  state: Object.assign({}, initialState),
  mutations: {
    changeDesigns (state, payload) {
      state.app.designs = payload
    },
    changeStyles (state, payload) {
      state.app.styles = payload
    },
    changeColors (state, payload) {
      state.app.colors = payload
    },
    changeSpecificColors (state, payload) {
      state.app.specificColors = payload
    },
    changeBrief (state, payload) {
      state.app.brief = payload
    },
    stepVisited (state, step) {
      state.visitedSteps.push(step)
    },
    reset (state) {
      state.app = Object.assign({}, initialState.app)
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    hasVisitedStep: (state) => (step) => {
      return state.visitedSteps.indexOf(step) !== -1
    }
  }
})
