<template>
  <v-app>
    <v-content>
      <header id="main-header">
        <v-container class="text-center">
          <img
            src="https://ngcom-static.s3.amazonaws.com/logo-horizontal-400x60.svg"
            alt="nickgreene.com"
            width="300"
            height="45"
          />
        </v-container>
      </header>

      <transition :name="transitionName">
        <router-view />
      </transition>
    </v-content>
  </v-app>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'App',
  data () {
    return {
      transitionName: ''
    }
  },
  watch: {
    $route (to, from) {
      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length
      this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
    }
  }
})
</script>

<style lang="scss">
@import "@/styles/variables.scss";

body {
  font-family: $body-font-family;
  font-size: 18px;
  font-weight: 300;
}

h1, h2, h3, h4, h5, h6 {
  font-family: filson-pro, sans-serif;
  font-weight: 400;
}

img {
  max-width: 100%;
}

#app .v-stepper__label small {
  font-weight: 400;
  margin-top: 4px;
}

#app.theme--light.v-application {
  background: #f8f8f8;
}

#main-header,
#secondary-header {
  background: #fff;
}

#secondary-header {
  margin-bottom: 80px;
  padding-top: 40px;
  .container {
    padding-bottom: 0;
  }
  h1 {
    margin-bottom: 1rem;
  }
  .app-steps {
    margin: 0;
    transform: translateY(50%);
  }
}

.control-bar {
  align-items: center;
  display: flex;
}

.control-bar__next {
  margin-left: 1rem;
}

.v-footer--fixed {
  box-shadow: 0 0 20px rgba(0,0,0,.2);
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.25s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(1em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-1em, 0);
}

#app .theme--light.v-stepper .v-stepper__step__step {
  color: #000;
}

#app.v-application .primary {
  color: #000 !important;
}
</style>
